import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Feature } from '../../mtp-common/models/feature.model';
import { UserService } from '../../mtp-common/services/user.service';

export class FeatureGuard {
    public static forFeature(feature: Feature): unknown {
        @Injectable({
            providedIn: 'root',
        })
        class FeatureCheck implements CanActivate {
            public constructor(private readonly router: Router, private readonly userService: UserService) {}

            public canActivate(
                _route: ActivatedRouteSnapshot,
                _state: RouterStateSnapshot
            ): Observable<boolean | UrlTree> {
                return this.userService
                    .hasFeature(feature)
                    .pipe(map((hasFeature) => hasFeature || this.router.createUrlTree(['/'])));
            }
        }

        return FeatureCheck;
    }
}
